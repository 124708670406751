body {
    margin: 0px;
    padding: 0px;
    background: "#111111";

    /* background: linear-gradient(180deg, rgba(111, 0, 246, 0.101) 0%, rgba(2,0,36,0) 80%); */
  }
  
  .floating {
    animation-name: floating;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes floating {
    0% {
      transform: translate(0, 0px);
    }
  
    50% {
      transform: translate(0, 8px);
    }
  
    100% {
      transform: translate(0, -0px);
    }
  }
  
  .gradientGreen {
    background: radial-gradient(circle, rgba(0, 255, 187, 0.4125) 0%,rgba(177, 178, 181, 0) 100%);
}